import { SVGProps } from 'react';

function Copyright(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_778_21900)">
        <path d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259972 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C11.9983 4.40923 11.3656 2.88411 10.2407 1.75926C9.1159 0.634414 7.59077 0.00172054 6 0V0ZM6 11C5.0111 11 4.0444 10.7068 3.22215 10.1573C2.39991 9.60794 1.75904 8.82705 1.38061 7.91342C1.00217 6.99979 0.90315 5.99445 1.09608 5.02455C1.289 4.05464 1.76521 3.16373 2.46447 2.46447C3.16373 1.7652 4.05465 1.289 5.02455 1.09607C5.99446 0.903148 6.99979 1.00216 7.91342 1.3806C8.82705 1.75904 9.60794 2.3999 10.1574 3.22215C10.7068 4.04439 11 5.01109 11 6C10.9985 7.32564 10.4713 8.59656 9.53393 9.53393C8.59656 10.4713 7.32564 10.9985 6 11Z" />
        <path d="M7.76799 4.232C7.86229 4.32308 7.98859 4.37348 8.11969 4.37234C8.25079 4.3712 8.3762 4.31862 8.4689 4.22591C8.5616 4.13321 8.61419 4.0078 8.61533 3.8767C8.61647 3.7456 8.56607 3.6193 8.47499 3.525C7.98549 3.03555 7.36185 2.70225 6.68293 2.56723C6.00401 2.43221 5.30029 2.50154 4.66077 2.76646C4.02125 3.03137 3.47464 3.47998 3.09008 4.05554C2.70551 4.63111 2.50024 5.30778 2.50024 6C2.50024 6.69222 2.70551 7.3689 3.09008 7.94446C3.47464 8.52002 4.02125 8.96863 4.66077 9.23355C5.30029 9.49846 6.00401 9.5678 6.68293 9.43278C7.36185 9.29776 7.98549 8.96445 8.47499 8.475C8.56607 8.3807 8.61647 8.2544 8.61533 8.1233C8.61419 7.9922 8.5616 7.8668 8.4689 7.77409C8.3762 7.68139 8.25079 7.6288 8.11969 7.62767C7.98859 7.62653 7.86229 7.67692 7.76799 7.768C7.41833 8.11757 6.97288 8.3556 6.48794 8.45201C6.00301 8.54842 5.50037 8.49888 5.0436 8.30964C4.58682 8.1204 4.19641 7.79997 3.92174 7.38886C3.64706 6.97775 3.50046 6.49443 3.50046 6C3.50046 5.50558 3.64706 5.02225 3.92174 4.61114C4.19641 4.20004 4.58682 3.8796 5.0436 3.69037C5.50037 3.50113 6.00301 3.45158 6.48794 3.54799C6.97288 3.6444 7.41833 3.88244 7.76799 4.232Z" />
      </g>
      <defs>
        <clipPath id="clip0_778_21900">
          <rect width={12} height={12} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Copyright;
